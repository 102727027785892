import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'goodwood',
  templateUrl: './goodwood.component.html',
  styleUrls: ['./goodwood.component.sass']
})
export class GoodwoodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
