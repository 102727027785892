<body class="text-center text-dark frontPgImg">
  <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">

<main role="main" class="inner cover">
  <h1 class="cover-heading"><em>Good business sense for good people.</em></h1>
  <p class="lead">Providing tailored software solutions to the entrepreneur.</p>
  <p class="lead">
    <a routerLink="/products" class="btn btn-lg btn-secondary">See package pricing</a>
  </p>
</main>
</div>
</body>

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7">
    <h2 class="featurette-heading">Get the tools your small business needs.</h2>
    <p class="lead">At Maetech.ca, we strongly believe <em>software is only a tool</em>, <strong>not a replacement of good workmanship</strong>.</p>
    <p class="lead">Which is why for nearly 8 years, we have built a standard of software reliable affordable and specifically designed for you.</p>
    <br>
    <h4 class="lead text-muted"><strong>How do we do this?</strong></h4>
    <p class="lead">Business Owners want to focus on what matters - <em>generating revenue</em>. MaeTech.ca manages the rest; we maintain direct and open communication with all users to ensure timely and maintainable solutions.</p>
    <br>
    <button type="button" class="btn btn-lg btn-block btn-primary"routerLink="/contact">Get started</button>

  </div>
  <div class="col-md-5">
    <svg class="bd-placeholder-img jumbotron bd-placeholder-img-lg featurette-image img-fluid mx-auto officeImg" width="500" height="500" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 500x500"></svg>
  </div>
</div>

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7 order-md-2">
    <h2 class="featurette-heading">Built to last.<span class="text-muted"> Built for you.</span></h2>
    <p class="lead">Any and all software created by MaeTech.ca is built to fullfill the needs of the user. Whereas, most software companys build for the buyer; they build in bulk.</p>
    <br>
    <h4 class="lead text-muted"><strong>Whats the difference?</strong></h4>
    <p class="lead">Do you post your receipts to your accounting software? What about the new leads to your customer relations software?<br>    ... probably not, so why are you shopping for the software?... you're not going to use it - infact, the clerk will be using it, relying on it, <em>the most.</em></p>
    <br> <h4 class="lead text-muted"><strong>We cant stress enough, the importance of the user-software relationship</strong></h4>

    <p class="lead">Thats why MaeTech.ca politely steps in and discusses the individual needs of each user. This way, the tools required can be specifically identified; giving your employees their very own environment that <em>will increase productivity and job satisfaction</em>.</p>
    <button type="button" class="btn btn-lg btn-block btn-primary"routerLink="/contact">Get started</button>
  </div>
  <div class="col-md-5 order-md-1 ">
    <svg class="bd-placeholder-img jumbotron bd-placeholder-img-lg featurette-image img-fluid mx-auto fingerImg" width="500" height="500" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 500x500"></svg>
  </div>
</div>

<hr class="featurette-divider">

<div class="row featurette">
  <div class="col-md-7">
    <h2 class="featurette-heading"><span class="text-muted">Have multpile softwares?</span> Enough is Enough!</h2>
    <p class="lead">Small businesses, time and time again, purchase software after software to fullfill whichever task their trying to complete. Be Gone with this!</p>
    <p class="lead">Get your business on the right track with <em>one software</em> that will grow with the needs of your business.</p>
    <br>
    <h4 class="lead text-muted"><strong>Have...</strong></h4>

    <ul class="list-unstyled lead mt-3 mb-4">
      <li>One place to go.</li>
      <li>One place for all of your information.</li>
      <li>One person to contact when you need some help.</li>
    </ul>
    <h4 class="lead text-muted text-right"><strong>... it's that easy.</strong></h4> <br>
    <button type="button" class="btn btn-lg btn-block btn-primary"routerLink="/contact">Get started</button>


  </div>
  <div class="col-md-5 ">
    <svg class="bd-placeholder-img jumbotron bd-placeholder-img-lg featurette-image img-fluid mx-auto womanImg" width="500" height="500" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 500x500"></svg>
  </div>
</div>
