<div class="pricing-header px-3 py-3 pt-md-5 pb-md-4 mx-auto text-center">
    <h1 class="display-4">Packages</h1>
    <p class="lead">You and your Business have alot going on. With the COVID-19 pandemic, all of us need to change the way we do business.</p>
    <hr>
    <div>
        <ul class="lead list-unstyled">
            <li>You could start with Casper; <strong>get a web-presence within 24 hours</strong> that'll knock your socks off!</li>
            <li>How about Sparrow; <strong>electrify your store front</strong>!</li>
            <li>Maybe the enterprise package is the right fit; Jynx will <strong>get you and your employees back into the (web) office.</strong></li>
        </ul>
        <p class="lead text-right">... all, with the virtual touch of MaeTech.ca</p>
    </div>
</div>
  
  <div class="container">
    <div class="card-deck mb-3 text-center">



      <div class="card mb-4 shadow-sm">
        <div class="card-body card-deck">

            <div class="card mb-4 shadow-sm">
                <div class="card-header">
                <h4 class="my-0 font-weight-normal">Casper</h4>
                <h5><small class="text-muted">Professional Web Management</small></h5>
                </div>
                <div class="card-body">
                <h5><small class="text-muted"><em>A website just for you</em>, <br> <br> starting at</small></h5>
                <h1 class="card-title pricing-card-title">$8 <small class="text-muted">/ mo</small></h1>
                <hr><br>
                <ul class="list-unstyled mt-3 mb-4">
                    <li>Custom domain included</li>
                    <li>Be the first on Google searches</li>
                    <li>Online appointment booking included</li>
                    <li>Be Secured with SSL</li>
                </ul>
                <br>
                <button type="button" class="btn btn-lg btn-block btn-primary"routerLink="/contact">Get started</button>
                <button type="button" class="btn btn-lg btn-block btn-secondary"routerLink="/about">Learn More</button>
                </div>
            </div>

            <div class="card mb-4 shadow-sm">
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal">Sparrow</h4>
                  <h5><small class="text-muted">E-commerce Management</small></h5>
                </div>
                <div class="card-body">
                <h5><small class="text-muted"><em>An electrifying store</em>, <br> <br> starting at</small></h5>
                <h1 class="card-title pricing-card-title">$45 <small class="text-muted">/ mo</small></h1>
                <hr>
                <ul class="list-unstyled mt-3 mb-4">
                    <li>Casper, plus
                </ul>
                  <ul class="list-unstyled mt-3 mb-4">
                    <li>Save up to 40% on Canada Post shipping</li>
                    <li>Competitive Merchant Rates</li>
                    <li>Inventory Management</li>
                    <li>Social Media & Email Marketing</li>
                  </ul>
                  <button type="button" class="btn btn-lg btn-block btn-primary"routerLink="/contact">Get started</button>
                  <button type="button" class="btn btn-lg btn-block btn-secondary"routerLink="/about">Learn More</button>
                </div>
              </div>
        
              <div class="card mb-4 shadow-sm">
                <div class="card-header">
                  <h4 class="my-0 font-weight-normal">Jynx</h4>
                  <h5><small class="text-muted">Business Management Software</small></h5>
                </div>
                <div class="card-body">
                <h5><small class="text-muted"><em>Integrated Solutions</em>, <br> <br> starting at</small></h5>
                <h1 class="card-title pricing-card-title">$85 <small class="text-muted">/ mo</small></h1>
                <hr>
                <ul class="list-unstyled mt-3 mb-4">
                    <li>Casper, plus
                </ul>
                  <ul class="list-unstyled mt-3 mb-4">
                    <li>Accounting & Payroll Management</li>
                    <li>Real-Time messaging</li>
                    <li>Optional PM, CRM & HRM Modules</li>
                    <li>Security options with HTTPS</li>
                    <li>Custom Dashboards for all users</li>
                  </ul>
                  <button type="button" class="btn btn-lg btn-block btn-primary"routerLink="/contact">Get started</button>
                  <a class="btn btn-lg btn-block btn-mae colour" href="http://my.maetech.ca">Launch Demo</a>

                </div>
              </div>
              <div class="btn-block">
                <div class="card-header">
                  <h5><small class="text-muted">Save the headache...</small></h5>
                  <h4 class="my-0 font-weight-normal">Bundle with Steele Co</h4>
                </div>        
                <div class="list-unstyled mt-3 mb-4 text-center">
                    <p>Steele Co provides bookkeeping and administrative services.<br>
                    Get the Steele Company to represent your books; you <strong>get integrated, real-time services</strong> right from your Sparrow or Jynx packages!</p>
                    <a class="btn btn-lg btn-block btn-secondary" href="http://steelecompany.ca/services">Get Bundled Bookkeeping Support</a>
                </div>
              </div>
              
        </div>
      </div>

    </div>
</div>
