<nav class="navbar navbar-expand-md navbar-light bg-light fixed-top">
    <a class="navbar-brand" routerLink="/" (click)="isMenuCollapsed = true"> <img class="" src="../../favicon.ico" alt="" width="35" height="35"> MaeTech.ca</a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" 
      aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation"(click)="isMenuCollapsed = !isMenuCollapsed">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div [ngbCollapse]="isMenuCollapsed" class="collapse navbar-collapse" id="navbarsExampleDefault">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item">
                <a class="nav-link" routerLink="products" (click)="isMenuCollapsed = true">Packages</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="about" (click)="isMenuCollapsed = true">About</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLink="contact" (click)="isMenuCollapsed = true">Contact</a>
            </li>
            <li >
                    <a class="nav-link btn  btn-mae text-light" href="http://my.maetech.ca" (click)="isMenuCollapsed = true">Launch Demo</a>
            </li>
        </ul>
    </div>
</nav>
