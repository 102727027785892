<ngb-carousel [interval]="6000" *ngIf="images">
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper Img">
        <img [src]="images[0]" alt="Random first slide">
      </div>
      <div class="carousel-caption front jumbotron">
        <h3 class="lead text-left">Have...</h3>
        <h3 class="" >One place to go</h3>
        <h3 class = "lead text-right">... its that easy.</h3>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper Img">
        <img [src]="images[1]" alt="Random second slide">
      </div>
      <div class="carousel-caption front jumbotron">
        <h3 class="" >One person to contact when you need some help.</h3>
        <h3 class = "lead text-right">... its that easy.</h3>
      </div>
    </ng-template>
    <ng-template ngbSlide>
      <div class="picsum-img-wrapper Img">
        <img [src]="images[2]" alt="Random third slide">
      </div>
      <div class="carousel-caption front jumbotron">
        <h3 class="" >One place for your information</h3>
        <h3 class = "lead text-right">... its that easy.</h3>
      </div>
    </ng-template>
  </ngb-carousel>

  <hr>

  <div class="container marketing">

    <!-- Three columns of text below the carousel -->
    <div class="row">
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle saplingImg" width="140" height="140" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 140x140"></svg>
        <h2>Our Passion</h2>
        <p>We provide custom software services designed specifically for small to medium sized businesses to hasten administrative tasks, streamline day-to-day processes and better equip the staff with live data and smart reporting; all within a personal, friendly interface.</p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle smallTreeImg" width="140" height="140" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 140x140"></svg>
        <h2>Our Place</h2>
        <p>Proudly Canadian; MaeTech.ca operates out of North Durham, Ontario. Using one-size-fits-all software is expensive; local software services are here. Call someone who cares and wants to continually find faster and better solutions.<br>Call MaeTech.ca</p>
      </div><!-- /.col-lg-4 -->
      <div class="col-lg-4">
        <svg class="bd-placeholder-img rounded-circle treeImg" width="140" height="140" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 140x140"></svg>
        <h2>Our Promise</h2>
        <p>Too many times has software dissapointed entrepreneurs in dire need of reliable, predictable and manageable information. Which is why <em>we promise</em> to renew small-business solutions that are built for you.</p>
      </div>
    </div><!-- /.row -->
