import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bs-footer',
  templateUrl: './bs-footer.component.html',
  styleUrls: ['./bs-footer.component.sass']
})
export class BsFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
