<!--Section: Contact v.2-->
<section class="mb-4">

    <!--Section heading-->
    <h2 class="h1-responsive font-weight-bold text-center my-4">Contact us</h2>
    <!--Section description-->
    <p class="text-center w-responsive mx-auto mb-5">Do you have any questions? Please do not hesitate to reach out.
        MaeTech.ca will come back to you within
        a matter of hours to help you.</p>

    <!--Grid column-->
    <div class="text-center w-responsive mx-auto mb-5">
        <ul class="list-unstyled mb-0">
            <li><i class="fas fa-map-marker-alt fa-2x"></i>
                <p>Durham ON, Canada</p>
            </li>

            <li><i class="fas fa-phone mt-4 fa-2x"></i>
                <p>(647) 955 2132</p>
            </li>

            <li><i class="fas fa-envelope mt-4 fa-2x"></i>
                <p>carter@maetech.ca</p>
            </li>
        </ul>
    </div>
    <!--Grid column-->


</section>